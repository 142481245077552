<template>
  <Form as="div" v-slot="{ meta }" class="form">
    <input-with-label-validation
      v-model="name"
      :placeholder="$t('profileContactName')"
      :required="true"
      name="name"
      type="text"
      rules="required"
    />
    <input-with-label-validation
      v-model="email"
      :placeholder="$t('profileEmail')"
      :required="true"
      iconName="mail"
      name="email"
      type="email"
      rules="email|required"
    />
    <input-with-label-validation
      v-model="password"
      :placeholder="$t('profilePass')"
      :required="true"
      iconName="pass"
      name="password"
      type="password"
      rules="required"
    />
    <!--  this component need for check what account type was selected -->
    <input-with-label-validation
      name="isCompany"
      type="number"
      :value="isCompany"
      rules="required"
      :style="{ display: 'none'}"
    />
    <div class="profile-grid">
      <div class="label required">{{ $t('profileAcc') }}</div>
      <div class="profile-account-selectors">
        <BaseCheckbox
          label="Personal"
          :trueValue="0"
          :falseValue="1"
          :modelValue="isCompany"
          v-model="isCompany"
        />
        <BaseCheckbox
          label="Company"
          :trueValue="1"
          :falseValue="0"
          :modelValue="isCompany"
          v-model="isCompany"
        />
      </div>
    </div>
    <div>
      <SimpleButton
        class="profile-button"
        :value="$t('profileRegistration')"
        :disabled="!meta.valid"
        @click="submit"
      />
    </div>
    <div class="registration-note">
      <span class="terms">
        {{ $t('termsPreview')}} <a target="_blank" class="terms-link" :href="termsOfServiceURL">{{ $t('terms') }}</a>
      </span>
      <span class="form-note">
        {{ $t('haveProfile') }}
      </span>
      <router-link
        class="form-note__link text-14-700"
        to="/"
      >{{ $t('login') }}</router-link>
    </div>
  </Form>
</template>

<script>
import en from '@vee-validate/i18n/dist/locale/en.json'
import cn from '@vee-validate/i18n/dist/locale/zh_CN.json'
import VueI18n from 'shared/src/i18n.js'
import SimpleButton from 'smarket-ui/src/components/buttons/SimpleButton'
import BaseCheckbox from './BaseCheckbox'
import { mapGetters } from 'vuex'
import InputWithLabelValidation from './InputWithLabelValidation'
import { Form } from 'vee-validate'

export default {
  name: 'TheRegistrationForm',
  components: {
    InputWithLabelValidation,
    BaseCheckbox,
    SimpleButton,
    Form,
  },
  data () {
    return {
      name: '',
      email: '',
      password: '',
      isCompany: null,
    }
  },
  mounted () {
    // localize('en', this.currentLocale)
  },
  computed: {
    ...mapGetters(['locale']),
    currentLocale () {
      return VueI18n.locale === 'en' ? en : cn
    },
    user () {
      return {
        contactName: this.name,
        email: this.email,
        password: this.password,
        isCompany: this.isCompany,
      }
    },
    termsOfServiceURL () {
      switch (this.locale) {
        case 'cn':
          return 'https://smarket.hk/privacy-policy/'
        default:
          return 'https://smarket.hk/en/privacy-policy/'
      }
    },
  },
  watch: {
    currentLocale () {
      // localize('en', this.currentLocale)
    },
  },
  methods: {
    async submit () {
      try {
        await this.$store.dispatch('registration', this.user)
        this.$router.push('/success')
      } catch (error) {
        const description = error.response && error.response.data && error.response.data.message
          ? error.response.data.message : this.$t('registrationError')
        this.$store.commit('showHintNotification', {
          type: 'ERROR',
          title: this.$t('copyErrorTitle'),
          description,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.form {
  max-width: 560px;
  padding: 40px 80px;
  box-shadow: 0 0 24px 0 #0000000F;
  border-radius: 20px;
}
.profile-grid {
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-template-areas: "a b";
  grid-gap: 15px;
  align-items: start;
  margin-bottom: 30px;

  .required {
    position: relative;
    padding-left: 11px;
    width: auto;
    margin: 0;

    &:before {
      left: 0;
    }
  }
}

.profile-account-selectors {
  display: flex;
}

.profile-button {
  width: 100%;
  margin-bottom: 21px;
  font-size: 16px;
}

.label {
  text-align: left;
  margin-right: 9px;
}

.registration-note {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.terms {
  font-size: 12px;
  color: $text-gray;

  &-link {
    color: $text-gray;
    font-size: 12px;
  }
}
.form-note__link {
  text-decoration: none;
}
</style>
