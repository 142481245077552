<template>
  <div class="main-content">
    <div class="main-content__inner __registration">
      <div class="container">
        <the-registration-form/>
      </div>
    </div>
  </div>
</template>

<script>
import TheRegistrationForm from '@/components/ui/forms/TheRegistrationForm.vue'

export default {
  name: 'Registration',
  components: {
    TheRegistrationForm,
  },
  mounted () {
    this.$store.dispatch('clearProfile')
  },
}

</script>

<style lang="scss" scoped>

</style>
